<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Valor Aproveitado de Incentivo
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-0">
        <v-spacer></v-spacer>
        <div class="table-v-action-button mr-3 pt-1" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <v-menu offset-y dense>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="table-v-action-button mr-3 pt-1"
            >
              <v-icon>mdi-file-export-outline</v-icon>Exportação
            </div>
          </template>
          <v-list>
            <v-list-item
              style="cursor: pointer"
              @click="exportCsv(arrayToPrint, headerToPrint)"
            >
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-delimited-outline</v-icon>Exportar CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              style="cursor: pointer"
              @click="exportXlsx2(relatorioFormatado, exportOptions)"
            >
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-excel-outline</v-icon>Exportar XLSX
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <quarter-filter
          style="margin-top: -2px"
          class="mb-n1"
          v-model="filtroTrimestre"
        ></quarter-filter>
        <div class="table-v-action-button mr-3 pt-1" @click="doLoad">
          <v-icon>mdi-magnify</v-icon>
        </div>
      </div>
    </v-card-text>
    <v-row class="m-0">
      <v-col class="p-0 white">
        <v-data-table
          style="border-right: thin solid rgba(0, 0, 0, 0.12)"
          data-app
          :items="relatorioFormatado"
          :headers="colunas"
          :footer-props="{
            'items-per-page-options': [-1],
          }"
          hide-default-footer
          fixed-header
        >
          <template v-slot:item="{ item, headers }">
            <tr
              class="table-v-tr"
              style="cursor: default"
              :class="{ 'font-weight-bold blue lighten-5': item.highlight }"
            >
              <td
                v-for="col in headers"
                v-bind:key="col.value"
                :class="{
                  nowrap: col.nowrap,
                  'text-end': col.align == 'end',
                  'font-weight-bold': item.highlight,
                }"
              >
                {{ col.format ? col.format(item[col.value]) : item[col.value] }}
                <span
                  v-if="item.asterisco && col.value != 'valor'"
                  class="red--text"
                  >*</span
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="p-3 font-weight-medium red--text blue lighten-5 footer">
          * Valor utilizado para fins de cálculo do incentivo fiscal.
        </div>
      </v-col>
      <v-col class="p-0 white">
        <div
          class="font-weight-medium chart-title"
        >
          Total de Dispêndio e Incentivo Utilizado
        </div>
        <div style="height: 280px" class="py-4 pl-4 pr-5">
          <bar-chart
            style="height: 280px"
            :options="chartOptions"
            :chartdata="chartData"
          ></bar-chart>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  directives: { mask: mask },
  components: {
    "bar-chart": () => import("@/components/bar-chart.vue"),
    "quarter-filter": () => import("@/components/quarter-filter.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "selectedClient"]),
    isClient: function () {
      return this.getClient().isClient;
    },
    resource: function () {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/dispendio/resumoped`
      );
    },
    relatorioFormatado: function () {
      return Object.keys(this.descricoes).map((key) => ({
        descricao: this.descricoes[key],
        valor: this.relatorio[key],
        highlight: this.highlights[key],
        asterisco:
          (key == "reducao_calc" &&
            this.relatorio.reducao_calc < this.relatorio.lalur) ||
          (key == "lalur" &&
            this.relatorio.lalur <= this.relatorio.reducao_calc),
      }));
    },
    arrayToPrint: function () {
      var array = [];
      array.push(this.colunas.map((c) => c.text));
      this.relatorioFormatado.forEach((row) => {
        array.push(
          this.colunas.map((c) =>
            c.format ? c.format(row[c.value]) : row[c.value]
          )
        );
      });
      array.forEach((row, ri) => {
        row.forEach((cell, ci) => {
          if (typeof cell === "string" && cell.includes("R$ ")) {
            array[ri][ci] = cell.replace("R$ ", "");
          }
        });
      });
      return array;
    },
    headerToPrint: function () {
      const { trimestreIni, trimestreFim, anoBase } = this.filtroTrimestre;
      const triLabel = ["primeiro", "segundo", "terceiro", "quarto"];
      return {
        Título: "Valor Aproveitado de Incentivo",
        Empresa: this.selectedClient.fantasia,
        Trimestre: `Do ${triLabel[trimestreIni - 1]} trimestre${
          trimestreFim ? ` até o ${triLabel[trimestreFim - 1]} trimestre` : ""
        } de ${anoBase}`,
      };
    },
    chartData: function () {
      var dataLabel = [
        "total_dispendio",
        "reducao_calc",
        "lalur",
        "incentivo_fical",
      ]
        .map((key) => ({
          label: this.descricoes[key],
          value: this.relatorio[key],
        }))
        .sort((dl1, dl2) => dl2.value - dl1.value);

      return {
        labels: dataLabel.map((dl) => dl.label),
        datasets: [
          {
            backgroundColor: this.$chartColors,
            borderColor: this.$chartColors,
            data: dataLabel.map((dl) => dl.value),
            hidden: false,
            label: "Total",
          },
        ],
      };
    },
    chartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: { line: { tension: 0.000001 } },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.toCurrency(tooltipItem.value);
            },
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return this.abbreviateNumber(value);
                  //return this.$options.filters.toCurrencyValue(value);
                },
              },
            },
          ],
        },
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
      };
    },
    exportOptions: function () {
      return {
        columns: this.colunas,
        trimestre: this.filtroTrimestre,
        styles: [1, 8, 9, 13].reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true,
              },
            },
          }),
          {}
        ),
      };
    },
  },
  created: function () {
    this.doLoad();
  },
  methods: {
    doLoad: function () {
      const { trimestreIni, trimestreFim, anoBase } = this.filtroTrimestre;
      this.resource
        .get({
          query: `ano=${anoBase}&trimestreIni=${trimestreIni}${
            trimestreFim ? `&trimestreFim=${trimestreFim}` : ""
          }`,
        })
        .then((response) => {
          if (!response.error) {
            this.relatorio = response;
          }
        });
    },
    abbreviateNumber: function (value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", " mil", " mi", " bi", " tri"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 3; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 3) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(2);
        var formatter = new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
        newValue = formatter.format(shortValue) + suffixes[suffixNum];
      }
      return newValue;
    },
  },
  data: function () {
    return {
      relatorio: {},
      colunas: [
        { text: "Dispêndio", value: "descricao", sortable: false },
        {
          text: "Total",
          value: "valor",
          align: "end",
          sortable: false,
          format: this.$options.filters.toCurrency,
        },
      ],
      descricoes: {
        rh_exclusivo: "RH - P&D (Dedicação Exclusiva)",
        rh_parcial: "RH - P&D (Dedicação Parcial)",
        terceiros: "Serviços de Terceiros (P&D)",
        materiais: "Materiais (Protótipos)",
        total_dispendio: "Total Dispêndios com P&D",
        /* TODO: asterisco do menor */
        incentivo: "% Exclusão (incremento de pesquisadores)",
        reducao_calc: "Exclusão Aproveitável",
        lalur: "Lucro Tributável (LALUR)",
        reducao_irpj: "Redução IRPJ (15%)",
        reducao_adic_irpj: "Redução Adicional IRPJ (10%)",
        reducao_csll: "Redução CSLL (9%)",
        incentivo_fical: "Incentivo Fiscal (IRPJ e CSLL)",
      },
      highlights: {
        reducao_calc: true,
        lalur: true,
        incentivo_fical: true,
      },
      asterisco: {
        reducao_calc: true,
        lalur: true,
      },
      filtroTrimestre: {
        trimestreIni: 1,
        trimestreFim: undefined,
        anoBase: moment().format("YYYY"),
      },
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-title {
  background-color: var(--v-table-header-base) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: thin solid rgba(0, 0, 0, 0.12);
  height: 48px;
  padding-top: 14px;
  text-align: center;
}

.footer {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>
